import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'us-cms',
    loadChildren: () => import('./module/back-end/back-end.module').then(m => m.BackEndModule)
  },
  {
    path: '',
    loadChildren: () => import('./module/front-end/front-end.module').then(m => m.FrontEndModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
